export const environment = {
  production: false,
  baseURL: 'https://api.sukhisamaj.com/index.php/',
  mediaURL: 'https://api.sukhisamaj.com/uploads/',
  onesignal: {
    appId: '0f8fcc06-1404-45e2-bc16-6ea0bd6cf3e0',
    googleProjectNumber: '652617337964',
    restKey: 'MWI5YjMyMGQtN2MwZi00ZTM5LTlkYTgtOTM1NGI5MDYwODdh'
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: 'QWigYeuMat_ofAtleast32PoNuactCoW',
  default_country_code: '91'
};
